<template>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  computed:
  {
    user_language()
    {
      return this.$store.state.users.currentUser.default_language 
    }
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  created()
  {
        this.$store.dispatch("users/getCurrentUser", { context: this })
        .then(()=>{
          this.$i18n.locale = this.user_language ? this.user_language : "en"
        });      
  },
  watch:{
    user_language(newProp, OldProp){
      this.$i18n.locale = newProp    
    }
    
  }
}
</script>

<style>

</style>
