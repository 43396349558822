<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
    style="width: auto;"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.fullName || userData.first_name }}
        </p>
      </div>
      <b-avatar
        size="40"
        src=""
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
        style="color: #fff"
      >
        <feather-icon v-if="!userData.first_name" icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{ name: 'apps-users-edit', params: { id: 1 } }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      {{$t("My account")}}
    </b-dropdown-item>
    <!-- <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="BookmarkIcon"
        class="mr-50"
      />
      <span>My Subscription</span>
    </b-dropdown-item> -->
    <b-dropdown-item 
      v-if="this.$roles.isAdmin(this.userData)"  
      link-class="d-flex align-items-center"
      :to="{ name: 'users-feedback' }">
      <feather-icon size="16" icon="TrendingUpIcon" class="mr-50" />
      <span>{{$t("Users Feedback")}}</span>
    </b-dropdown-item>

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>{{$t("Sign Out")}}</span>
    </b-dropdown-item>
    </b-nav-item-dropdown
  >
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";
import Locale from "./Locale.vue";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    Locale
  },
  data() {
    return {
      avatarText,
    };
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    }
  },
  computed: {
    userData() {
      return this.$store.state.users.currentUser;
    },
  },
  created() {
    this.$store.dispatch("users/getCurrentUser", { context: this });
  },
};
</script>
