export default [
    {
        title: 'My Account',
        icon: 'UsersIcon',
        tagVariant: 'light-warning',
        route: { name: 'apps-users-edit', params: { id: 1 } },
    },
    {
        title: 'Logout',
        icon: 'LogOutIcon',
        tagVariant: 'light-warning',
        route: {name:'logout'},
    },
    {
        title: 'Users Feedback',
        icon: 'TrendingUpIcon',
        tagVariant: 'light-warning',
        route: {name:'users-feedback'},
    }
]  