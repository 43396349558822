<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav navbar-mobile">
      <li class="nav-item d-flex align-items-center">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
      <li class="logo-navbar-mobile">
        <b-link to="/home" class="navbar-logo">
          <img src="@/assets/images/logo/logo-dialog.svg" alt="" />
        </b-link>
      </li>
    </ul>

    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <ul class="center-menu mb-0">
        <li>
          <b-link to="/home">
            <feather-icon class="menu-icons" icon="HomeIcon" size="21" />
            {{$t("Home")}}
          </b-link>
        </li>
        <li>
          <b-link to="/remote">
            <feather-icon class="menu-icons" icon="SmartphoneIcon" size="21" />
            {{$t("Remote")}}
          </b-link>
        </li>
        <li>
          <b-link to="/history">
            <feather-icon class="menu-icons" icon="ClockIcon" size="21" />
            {{$t("History")}}
          </b-link>
        </li>
        <li v-if="showNav" >
          <b-link to="/user-management">
            <feather-icon class="menu-icons" icon="UsersIcon" size="21" />
            {{$t("User Management")}}
          </b-link>
        </li>
      </ul>
    </div>

    <b-navbar-nav class="nav align-items-center">
      <locale />
      <a class="mercatus-logo" href="https://cooling.mercatus.pt/pt/home" target="_blank">
        <img src="@/assets/images/logo/logo-mercatus-white.png" alt="" />
      </a>
      <user-dropdown class="d-none d-lg-block"/>
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from "bootstrap-vue";
import UserDropdown from "./components/UserDropdown.vue";
import Locale from "./components/Locale.vue";

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    UserDropdown,

    Locale,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      showNav: true,
    };
  },
  methods: {
    HideIfIsTechnicianOrDistributor() {
      this.showNav = !this.$roles.isDistributor(this.userData)
    },
  },
  computed:{
    userData() {
      return this.$store.state.users.currentUser;
    },
  },
  watch: {
    userData(newProp, oldProp) {
      this.HideIfIsTechnicianOrDistributor();
    },
  },
  created() {
      this.$store.dispatch("users/getCurrentUser", { context: this})
      this.$store.dispatch("users/getListGroups", { context: this });
    },
};
</script>
