<template>
  <p class="clearfix mb-0">
    <span 
    class="float-md-left d-block d-md-inline-block mt-20"
    style="font-size: 12px">
      <a class="mr-2" href="https://cooling.mercatus.pt/pt/home" target="_blank">
        <img src="@/assets/images/logo/logo-mercatus-black.png" alt="">
      </a>
      © {{ new Date().getFullYear() }}
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
